const ENTITY_TYPE_ATTRIBUTE = 'entity-type'

const _alterLabelColorOpacity = (label, opacity) => {
  const typeColor = label.getAttribute(ENTITY_COLOR_ATTRIBUTE)

  const typeLabel = label.firstElementChild
  typeLabel.style.backgroundColor = `${typeColor}${opacity}`
}

const _unselectAllLabels = () => {
  document.querySelectorAll('.tags.is-clickable').forEach((label) => {
    // Remove color opacity
    _alterLabelColorOpacity(label, '80')
  })
}

const _selectLabel = (label) => {
  SELECTED_LABEL = label.getAttribute(ENTITY_TYPE_ATTRIBUTE)

  // Restore color opacity
  _alterLabelColorOpacity(label, '')
}

export const getColor = (label) => {
  const entityType = document.querySelector(`[${ENTITY_TYPE_ATTRIBUTE}="${label}"]`)
  if (!entityType) return DEFAULT_ENTITY_COLOR
  return entityType.getAttribute(ENTITY_COLOR_ATTRIBUTE)
}

export const initLabels = () => {
  document.querySelectorAll('.tags.is-clickable').forEach(label => {
    label.addEventListener('click', () => {
      _unselectAllLabels()
      _selectLabel(label)
    })
  })

  // Select the first label by default
  const label = document.querySelector('.tags.is-clickable')
  if (!label) return
  label.click()
}

const ENTITY_COLOR_ATTRIBUTE = 'entity-color'
export const DEFAULT_ENTITY_COLOR = '#0a0a0a'

export let SELECTED_LABEL = ''
