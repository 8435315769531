import { isEmpty, isEqual, xorWith } from 'lodash'
import { initFormset } from '../utils/formset.js'
import { SELECTED_LABEL, initLabels } from './labels.js'
import { loadStoredEntities } from './storage.js'
import { createTag, addDeleteButton, displayEntities } from './base.js'
import { setUserTaskAnnotation, displayWarning } from '../annotations/storage.js'

const TRANSCRIPTION_TEXT_ID = 'transcription-text'

// List of characters not considered as being part of a word for full word selection
const FULL_WORDS_DELIMITERS = [' ', '\n']

const enforceFullWordSelection = (selection) => {
  /**
   * Shrinks or expands the range of a selection to round it to
   * characters that are not present in FULL_WORDS_DELIMITERS.
   */
  const currentRange = selection.getRangeAt(0)
  if (currentRange.startOffset === currentRange.endOffset) return currentRange
  let startIndex = currentRange.startOffset
  // The index of the last character of a selection, i.e. offset - 1
  let endIndex = currentRange.endOffset - 1
  const text = currentRange.startContainer.textContent

  // Determine the start index of the new selection
  if (FULL_WORDS_DELIMITERS.includes(text[startIndex])) {
    // If the start index is not within a word, shrink the selection forwards until we reach a word
    while (FULL_WORDS_DELIMITERS.includes(text[startIndex]) && startIndex < endIndex) startIndex++
  } else {
    // Else expand the selection backwards until we reach the last character being part of a word
    while (startIndex > 0 && !FULL_WORDS_DELIMITERS.includes(text[startIndex - 1])) startIndex--
  }

  // Determine the end index of the new selection
  if (FULL_WORDS_DELIMITERS.includes(text[endIndex])) {
    // If the end index is not within a word, shrink the selection backwards until we reach a word
    while (FULL_WORDS_DELIMITERS.includes(text[endIndex]) && endIndex >= startIndex) endIndex--
  } else {
    // Else expand the selection forwards until we reach the last character being part of a word
    while (endIndex < (text.length - 1) && !FULL_WORDS_DELIMITERS.includes(text[endIndex + 1])) endIndex++
  }

  const newRange = document.createRange()
  newRange.setStart(currentRange.startContainer, startIndex)
  // Restore the range end offset, i.e. index + 1
  newRange.setEnd(currentRange.endContainer, endIndex + 1)
  selection.removeAllRanges()
  selection.addRange(newRange)
  return newRange
}

const _addEntity = (userTaskID, parentID, entities, fullWordSelection) => {
  const selection = document.getSelection()

  if (selection.rangeCount !== 1) return
  let range = selection.getRangeAt(0)

  // Check that the selection is part of the transcription
  if (range.startContainer !== range.endContainer || range.startContainer.nodeName !== '#text') return

  if (fullWordSelection) range = enforceFullWordSelection(selection)

  const entityText = selection.toString()
  if (!entityText || !SELECTED_LABEL) return

  const transcription = document.getElementById(TRANSCRIPTION_TEXT_ID)
  const childNodes = Array.from(transcription.childNodes)
  const previousSiblings = childNodes.slice(0, childNodes.indexOf(range.startContainer))
  const offset = previousSiblings.reduce((offset, child) => child.textContent.length + offset, 0)
  const entity = {
    entity_type: SELECTED_LABEL,
    offset: offset + range.startOffset,
    length: entityText.length
  }
  entities.push(entity)
  setUserTaskAnnotation(userTaskID, parentID, entities)

  const tag = createTag(range.startContainer, range.startOffset, entity.length, entity.entity_type)
  addDeleteButton(userTaskID, parentID, entities, tag, entity, _removeEntity)
}

const _removeEntity = (entities, tag, entity) => {
  entities.splice(entities.indexOf(entity), 1)

  let text = tag.textContent

  // Restore text of the previous sibling
  if (tag.previousSibling.nodeType === Node.TEXT_NODE) {
    text = `${tag.previousSibling.textContent}${text}`
    tag.previousSibling.remove()
  }

  // Restore text of the next sibling
  if (tag.nextSibling.nodeType === Node.TEXT_NODE) {
    text = `${text}${tag.nextSibling.textContent}`
    tag.nextSibling.remove()
  }

  tag.parentElement.insertBefore(document.createTextNode(text), tag)
  tag.remove()
}

const initEntities = (userTaskID, parentID, transcription, entities, fullWordSelection) => {
  // Display entities of the previous version
  displayEntities(userTaskID, parentID, transcription, entities, _removeEntity)

  transcription.addEventListener('mouseup', () => {
    _addEntity(userTaskID, parentID, entities, fullWordSelection)
  })
}

export const bootEntitiesTranscriptionAnnotate = (userTaskID, parentID, previousEntities, fullWordSelection) => {
  const transcription = document.getElementById(TRANSCRIPTION_TEXT_ID)

  const entities = loadStoredEntities(userTaskID, parentID, transcription) || previousEntities
  if (!isEmpty(xorWith(entities, previousEntities, isEqual))) displayWarning(userTaskID, parentID)

  initEntities(userTaskID, parentID, transcription, entities, fullWordSelection)
  initLabels()
  initFormset(entities, (key, value) => value)
}
